/**
 * Foursource page
 * https://foursource.com/sustainability/wrap-x-foursource
 */

import * as React from "react";
import { withTrans } from "../../i18n/withTrans";
import Utm from "../../components/_global/utm";
import { pageTexts } from "../../content/wrap-x-foursource";
import { searchTexts } from "../../content/pages/searchCerts";

import { StaticImage } from "gatsby-plugin-image";

// components
import Layout from "../../components/layout";
import TextImgBackgroundFull from "../../components/textImgBackgroundFull";
import Text from "../../components/text";
import TitleSeparator from "../../components/titleSeparator";
import Button from "../../components/_partials/_button";
import BulletsSection from "../../components/bulletsSection";

class wrapPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
    this.searchTexts = searchTexts(this.t);
    this.searchTexts.searchText.subtitle.text = this.t("sustainability.wrap-x-foursource.searchingWRAP");
  }
  
  render() {
    return (
      <>
        <Layout pageName="sustainability.wrap-x-foursource" whiteMenu={false}>
          <TextImgBackgroundFull info={this.text.topInfo}></TextImgBackgroundFull>
          <div className="paddingSidesBig pt-30 pb-50">
            <Text text={this.text.firstText}></Text>
          </div>
          <div className="paddingSidesBig backgroundGrey topBottomDiagonal pt-30">
            <div className="pt-100 pb-100">
              <TitleSeparator info={this.text.whatIs}></TitleSeparator>
              <Text text={this.text.standardText}></Text>
              <div className="imgCenter text-center">
                <StaticImage src="../../images/logo-wrap.png" alt={this.t("sustainability.wrap-x-foursource.imgAlt")} placeholder="blurred" />
              </div>
              <div className="pt-50">
                <TitleSeparator info={this.text.titlePrinciples}></TitleSeparator>
                <div className="row row-cols-md-2 row-cols-sm-1 m-0">
                  {this.text.twoCols.map((col, i) => (
                    <BulletsSection key={i} info={col} num={i}></BulletsSection>
                  ))}
                </div>
                <div className="row justify-content-center">
                  <Button cta={this.text.WRAPButton}></Button>
                </div>
              </div>
            </div>
          </div>
          <div className="paddingSidesBig pt-30">
            <BulletsSection info={this.searchTexts.searchText}></BulletsSection>
            <a href={process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`)} target="_blank" rel="noreferrer">
            <div className="d-flex justify-content-center">
              <StaticImage
                src="../../images/wrap-04022020.png"
                alt={this.t("sustainability.wrap-x-foursource.platformAlt")}
                className="h-100"
                placeholder="blurred"
              />
              </div>
            </a>
            <div className="row justify-content-center pb-100">
              <Button cta={this.text.resultsButton}></Button>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(wrapPage);
