import CheckIcon from "../images/icon/check.svg";
import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/organic-textile_wrap.jpg";

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("sustainability.wrap-x-foursource.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("sustainability.wrap-x-foursource.topSubtitle"),
        colorClass: "colorLight",
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("sustainability.wrap-x-foursource.imgAlt"),
        smallHeight: true,
        bottomPosition: true
      },
    },
    firstText: [
      {
        text: t("sustainability.wrap-x-foursource.supplyChain"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
    ],
    whatIs: {
      title: {
        text: t("sustainability.wrap-x-foursource.whatIs"),
        colorClass: "colorDark",
      },
      text: {
        text: t("sustainability.wrap-x-foursource.wrapCerts"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    standardText: [
      {
        text: t("sustainability.wrap-x-foursource.workplaceStandards"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
    ],
    twoCols: [
      {
        icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
        separator: false,
        item: {
          text: [
            t("sustainability.wrap-x-foursource.complianceLaws"),
            t("sustainability.wrap-x-foursource.prohibitionLabor"),
            t("sustainability.wrap-x-foursource.prohibitionChild"),
            t("sustainability.wrap-x-foursource.prohibitionAbuse"),
            t("sustainability.wrap-x-foursource.compensation"),
            t("sustainability.wrap-x-foursource.hoursWork"),
          ],
          colorClass: "colorDark",
        },
        noPadding: true,
        customPBText: true,
      },
      {
        icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
        separator: false,
        item: {
          text: [
            t("sustainability.wrap-x-foursource.prohibitionDiscrimination"),
            t("sustainability.wrap-x-foursource.healthSafety"),
            t("sustainability.wrap-x-foursource.freedom"),
            t("sustainability.wrap-x-foursource.environment"),
            t("sustainability.wrap-x-foursource.customCompliance"),
            t("sustainability.wrap-x-foursource.security"),
          ],
          colorClass: "colorDark",
        },
        noPadding: true,
        customPBText: true,
      },
    ],
    titlePrinciples: {
      title: {
        text: t("sustainability.wrap-x-foursource.principles"),
        colorClass: "colorDark",
      },
      separator: true,
    },
    WRAPButton: {
      text: t("sustainability.wrap-x-foursource.learnMore"),
      url: "http://www.wrapcompliance.org/",
      type: "mainColor",
    },
    resultsButton: {
      text: t("sustainability.wrap-x-foursource.seeMore"),
      url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      type: "mainColor",
    },
  };
};
